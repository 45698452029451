import React, { Component } from 'react';
import { ResumeDataContext } from '../context';
import { Certification } from './Certification';
import { Education } from './Education';
import { Experience } from './Experience';

const titleClasses = 'text-1xl font-bold uppercase text-secondary-800 mb-1 mt-2';

export class ContentPage2 extends Component {
  render() {
    return (
      <>
        <div className="-mx-4 flex flex-col paper:flex-row print:flex-row">
          <div className="px-4 w-full paper:w-full print:w-full">
            <section>
              <ResumeDataContext.Consumer>
                {(data) => <Experience data={data.work.slice(1, data.work.length)} />}
              </ResumeDataContext.Consumer>
            </section>
          </div>
        </div>
        <div className="-mx-4 flex flex-col paper:flex-row print:flex-row">
          <div className="px-4 w-full paper:w-1/2 print:w-1/2">
            <section>
              <h2 className={titleClasses}>Education</h2>
              <hr className="border-primary-700 border-t-2 border-solid border-opacity-50" />
              <ResumeDataContext.Consumer>
                {(data) => <Education data={data.education} />}
              </ResumeDataContext.Consumer>
            </section>
          </div>
          <div className="px-4 w-full paper:w-1/2 print:w-1/2">
            <section>
              <h2 className={titleClasses}>Certification</h2>
              <hr className="border-primary-700 border-t-2 border-solid border-opacity-50" />
              <ResumeDataContext.Consumer>
                {(data) => <Certification data={data.certificates} />}
              </ResumeDataContext.Consumer>
            </section>
          </div>
        </div>
      </>
    );
  }
}
