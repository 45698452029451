import moment from 'moment';

// eslint-disable-next-line no-shadow
export enum DateFormats {
  Month_Year = 'MMM-YYYY',
  Full = 'LLL'
}

export const DateFormatter = (dt: string, format = DateFormats.Month_Year) => {
  return moment(dt).format(format);
};
