/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { faGripLinesVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DateFormats, DateFormatter } from '../../utils/date';

export class FooterComponent extends React.Component {
  state = { lastCommit: null };

  render() {
    const lc = this.state.lastCommit;
    if (!lc) {
      return null;
    } else {
      return (
        <div className="w-full text-center mt-4 mb-4 print:hidden">
          <p className="inline-block">
            <span className="text-primary-600">
              Last updated on {DateFormatter(lc.commit?.committer?.date, DateFormats.Full)}
            </span>
          </p>

          <FontAwesomeIcon
            icon={faGripLinesVertical}
            className="align-middle text-primary-600 mx-4"
          />
        </div>
      );
    }
  }
}
