import React from 'react';
import { Content } from '../Content/Content';
import { FooterComponent as Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { ContentPage2 } from '../Content/ContentPage2';

export const App = () => {
  return (
    <>
      <div className="print:bg-white print:py-3">
        <div
          className="flex flex-col mx-auto w-full paper:w-paper print:w-paper
            text-sm paper:text-70 print:text-70
            my-0 paper:my-20 print:my-0
            transform xl:scale-120 2xl:scale-10 3xl:scale-160 origin-top">
          <div
            className="h-auto paper:h-paper print:h-paper 
          bg-white p-4 sm:p-10 paper:p-12 print:p-12 
          shadow-none paper:shadow-2xl print:shadow-none justify-between">
            <Header />
            <Content />
          </div>
          <br />
          <div
            className="h-auto paper:h-paper print:h-paper 
            bg-white p-4 sm:p-10 paper:p-12 print:p-12 
            shadow-none paper:shadow-2xl print:shadow-none">
            <ContentPage2 />
          </div>
          <br />
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
