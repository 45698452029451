import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const ViewPDFButton = () => {
  return (
    <a
      href="./DHRUV_TRIVEDI_FULL_STACK_DEVELOPER_Resume.pdf"
      target="_blank"
      rel="noreferrer"
      className="text-1.25xl bg-secondary-200 hover:bg-secondary-300 py-1 px-2 rounded-md print:hidden">
      <FontAwesomeIcon icon={faDownload} className="mr-1" />
      PDF
    </a>
  );
};
