import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ResumeSchemaWork } from '../../data/resume-data.interface';
import { DateFormatter } from '../../utils/date';

export const Experience = (props: { data: ResumeSchemaWork[] }) => {
  const experiences = props.data.map((exp: ResumeSchemaWork, i: number) => {
    return (
      <div key={i}>
        <h3>
          <a
            className="text-0.85xl font-bold text-primary-700 hover:text-primary-600"
            href={exp.website}
            target="_blank"
            rel="noreferrer">
            {exp.company}
          </a>
        </h3>

        <h4 className="text-secondary-600">
          {DateFormatter(exp.startDate)} – {exp.endDate ? DateFormatter(exp.endDate) : 'Present'}
          &nbsp;|&nbsp;
          {exp.position}
        </h4>

        <h5>{exp.summary}</h5>
        <ul className="text-justify">
          {exp.highlights.map((hl) => {
            return (
              <li key={hl} className="flex ml-1">
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  className="mr-1 mt-1 text-secondary-600 align-middle"
                />
                <div>{hl}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  });
  return <div>{experiences}</div>;
};
