import { ResumeSchema } from './resume-data.interface';

export default {
  basics: {
    name: 'Dhruv Trivedi',
    label: '',
    picture: '',
    email: 'dhruv2093@gmail.com',
    website: '',
    summary:
      'Senior Full Stack Developer with over 7 years of experience across diverse industries including Healthcare, Public Sector, and Oil and Gas domains. Proficient in developing robust applications using frontend technologies such as Angular, JavaScript, and JQuery along with backend expertise in cutting-edge .NET frameworks like .NET 8, .NET 6, and .NET Framework 4.8. Skilled in managing databases like MS SQL, Oracle, and IBM DB2 using Entity Framework Core and Dapper for optimized interactions. Experienced in system logging with Serilog and NLog, real-time monitoring using Dynatrace and Keptn, and streamlining development workflows through Jfrog and Postman within Azure environments. A strong advocate for Clean Code Principles and adept at pioneering intricate Microservices Architecture aligned with Domain Driven Design (DDD) to enhance operational efficiency across industries.',
    location: {
      city: 'Ottawa',
      countryCode: 'CA',
      region: 'ON'
    },
    profiles: [
      {
        network: 'GitHub',
        username: 'dhruv2093',
        icon: 'github',
        url: 'https://github.com/dhruv2093'
      },
      {
        network: 'LinkedIn',
        username: 'trivedidhruv',
        icon: 'linkedin',
        url: 'https://www.linkedin.com/in/trivedidhruv'
      },
      {
        network: 'www.dhruvtrivedi.dev',
        username: 'www.dhruvtrivedi.dev',
        prefix: 'fa',
        icon: 'globe',
        url: 'https://www.dhruvtrivedi.dev/'
      }
    ]
  },
  work: [
    {
      company: 'Eviden - Atos Group',
      website: 'https://eviden.com',
      summary: 'As a Senior Full Stack Developer in the Healthcare domain, I currently:',
      highlights: [
        'Leverage .NET 8, .NET 6, and .NET Framework 4.8 to meticulously craft and deploy healthcare applications, ensuring precision and efficiency in meeting client requirements.',
        'Showcase extensive expertise in managing diverse databases such as MS SQL, Oracle, and IBM DB2. I utilize the power of Entity Framework Core and Dapper to optimize database interactions, ensuring seamless and high-performance operations.',
        'Implement precise logging mechanisms, utilizing tools like Serilog and NLog for meticulous tracking of system behaviors, maintaining a robust and error-free healthcare application.',
        'Proficiently utilize Dynatrace and Keptn to monitor and uphold optimal performance of Azure-hosted healthcare applications in real-time, ensuring consistent reliability and responsiveness.',
        'Streamline development workflows by employing Jfrog for artifact management and Postman for API testing, ensuring swift and efficient development processes.',
        'Showcase expertise in GIT, facilitating collaborative development efforts in intricate microservices architecture, ensuring a cohesive and scalable system.',
        'Successfully implemented IBM MQ for secure messaging within healthcare systems, ensuring the seamless and protected transfer of critical data.',
        'Pioneer the design and execution of intricate Microservices Architecture, fostering seamless interoperability between healthcare systems, resulting in enhanced operational efficiency and streamlined workflows.',
        'Apply Domain Driven Design (DDD) principles meticulously, aligning sophisticated healthcare software with the intricate domain models inherent in the industry, ensuring precision and efficacy.',
        'Develop sophisticated, data-driven applications capable of handling sensitive patient data securely, adhering strictly to stringent privacy and security standards.',
        'Collaborate extensively to implement comprehensive monitoring tools, DevOps practices, and innovative solutions within the healthcare ecosystem, contributing significantly to advancements in patient care and operational efficiency.'
      ],
      position: 'Sr Full Stack Developer',
      startDate: 'April 2022',
      tags: [
        {
          display: '.NET 6',
          website: 'https://dotnet.microsoft.com/'
        },
        {
          display: 'Microservices',
          website: 'https://microservices.io/'
        },
        {
          display: 'Clean Code Architecture',
          website:
            'https://docs.microsoft.com/en-us/dotnet/architecture/modern-web-apps-azure/common-web-application-architectures#clean-architecture'
        },
        {
          display: 'C#',
          website: 'https://docs.microsoft.com/en-us/dotnet/csharp/'
        },
        {
          display: 'Microsoft SQL Server',
          website: 'https://www.microsoft.com/en-us/sql-server'
        },
        {
          display: 'Azure DevOps',
          website: 'https://azure.microsoft.com/en-ca/services/devops/'
        },
        {
          display: 'jFrog',
          website: 'https://jfrog.com/artifactory/'
        },
        {
          display: 'Dynatrace',
          website: 'https://www.dynatrace.com/'
        },
        {
          display: 'Keptn',
          website: 'https://keptn.sh/'
        },
        {
          display: 'Automapper',
          website: 'https://automapper.org/'
        },
        {
          display: 'Dapper',
          website: 'https://github.com/DapperLib/Dapper'
        },
        {
          display: 'Entity Framework 6',
          website: 'https://docs.microsoft.com/en-us/ef/ef6/'
        }
      ]
    },
    {
      company: 'CGI Inc.',
      website: 'https://www.cgi.com/en/overview',

      summary:
        'I build features that help the Provincial Government/Canadian Students/Institutions Student AID and Finance operations efficiently.',
      highlights: [
        'Proficiently designed and developed pivotal features catering to the optimization of Provincial Government, Canadian Students, and Institutions Student AID and Finance operations, ensuring enhanced efficiency and streamlined workflows.',
        'Led a strategic initiative overseeing the migration of legacy MVC application code to Angular 12, leveraging cutting-edge technologies to enhance user experiences and system performance.',
        'Customized and fine-tuned a newly integrated CMS system, optimizing its functionality to meet specific organizational needs, resulting in improved usability and performance.',
        'Took charge of spearheading the successful implementation of the Canada Post Address Complete Service, enhancing address validation and accuracy within systems.',
        'Played a pivotal role in the initiative focused on leveraging and managing private packages via Azure Artifacts, ensuring secure and efficient access to essential resources.',
        'Enhanced the management and generation of NuGet Packages by amplifying processes using NSwag, contributing to streamlined development workflows and package reliability.',
        'Engineered PowerShell scripts to automate Git processes, significantly improving version control workflows and enhancing team productivity.',
        'Successfully managed the migration of .NET Core 2.1 projects to .NET Core 3.1, staying aligned with the latest technology trends to ensure system compatibility and optimal performance.'
      ],
      position: 'Full Stack Developer',
      startDate: 'December 2018',
      endDate: 'April 2022',
      tags: [
        {
          display: 'Angular',
          website: 'https://angular.io/'
        },
        {
          display: 'Typescript',
          website: 'https://www.typescriptlang.org/'
        },
        {
          display: '.NET Core',
          website: 'https://dotnet.microsoft.com/'
        },
        {
          display: 'C#',
          website: 'https://docs.microsoft.com/en-us/dotnet/csharp/tour-of-csharp/'
        },
        {
          display: 'Microsoft SQL Server',
          website: 'https://www.microsoft.com/en-us/sql-server'
        },
        {
          display: 'Azure DevOps',
          website: 'https://azure.microsoft.com/en-ca/services/devops/'
        },
        {
          display: 'Azure Artifacts',
          website: 'https://azure.microsoft.com/en-ca/services/devops/artifacts/'
        },
        {
          display: 'NSwag',
          website: 'https://github.com/RicoSuter/NSwag'
        },
        {
          display: 'NHibernate 5',
          website: 'https://nhibernate.info/'
        },
        {
          display: 'Entity Framework 6',
          website: 'https://docs.microsoft.com/en-us/ef/'
        }
      ]
    }
  ],
  education: [
    {
      institution: 'Sacred Heart University',
      location: 'CT, USA',
      website: 'https://www.sacredheart.edu/',
      area: 'Masters in Computer Science',
      studyType: 'Masters in Computer Science',
      startDate: '2017',
      endDate: '2018',
      gpa: '3.5',
      courses: [
        'Front-end Development',
        'Databases',
        'Algorithms',
        'Professional Communication',
        'Operating Systems'
      ]
    },
    {
      institution: 'Dharmsinh Desai University',
      location: 'GJ, India',
      website: 'https://www.ddu.ac.in/',
      area: 'Bachelor of Technology in Computer Science',
      studyType: 'Bachelor',
      startDate: '2012',
      endDate: '2015',
      gpa: '',
      courses: [
        'Operating Systems',
        'Software Foundation',
        'Computer Architecture',
        'Algorithms',
        'Project Planning',
        'Cloud Computing',
        'Content Management',
        'Desktop and Web Applications Design'
      ]
    },
    {
      institution: 'Gujarat Technological University',
      location: 'GJ, India',
      website: 'https://www.gtu.ac.in/',
      area: 'Diploma in Computer Science',
      studyType: 'Diploma',
      startDate: '2008',
      endDate: '2012',
      gpa: '',
      courses: [
        'Operating Systems',
        'Software Foundation',
        'Computer Architecture',
        'Algorithms',
        'Project Planning',
        'Cloud Computing',
        'Content Management',
        'Desktop and Web Applications Design'
      ]
    }
  ],
  skills: [
    {
      name: 'Core Competencies',
      keywords: [
        'Technical expertise',
        'Leadership',
        'Collaboration',
        'Continuous learning',
        'Problem-solving',
        'Adaptability'
      ]
    },
    {
      name: 'Languages',
      keywords: [
        {
          display: 'JavaScript',
          website: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
        },
        {
          display: 'TypeScript',
          website: 'https://www.typescriptlang.org/'
        },
        {
          display: 'C♯',
          website: 'https://en.wikipedia.org/wiki/C_Sharp_(programming_language)'
        },
        {
          display: 'NoSQL',
          website: 'https://www.mongodb.com/nosql-explained'
        },
        {
          display: 'SQL',
          website: 'https://en.wikipedia.org/wiki/SQL'
        },
        {
          display: 'SSRS',
          website: 'https://en.wikipedia.org/wiki/SQL'
        },
        {
          display: 'HTML',
          website: 'https://en.wikipedia.org/wiki/HTML'
        },
        {
          display: 'CSS',
          website: 'https://en.wikipedia.org/wiki/CSS'
        }
      ]
    },
    {
      name: 'Frameworks/Libraries',
      keywords: [
        {
          display: 'Angular',
          website: 'https://angular.io/'
        },
        {
          display: '.NET Core',
          website: 'https://reactjs.org/'
        },
        {
          display: '.NET Framework',
          website: 'https://www.asp.net/'
        },
        {
          display: 'jQuery',
          website: 'https://jquery.com/'
        },
        {
          display: 'Angular Material',
          website: 'https://material.angular.io/'
        },
        {
          display: 'Ng-Bootstrap',
          website: 'https://ng-bootstrap.github.io/#/home'
        },
        {
          display: 'Ngx-Bootstrap',
          website: 'https://ng-bootstrap.github.io/#/home'
        },
        {
          display: 'Automapper',
          website: 'https://automapper.org/'
        }
      ]
    },
    {
      name: 'Tools',
      keywords: [
        {
          display: 'Visual Studio',
          website: 'https://www.docker.com/'
        },
        {
          display: 'Jira',
          website: 'https://www.atlassian.com/software/jira'
        },
        {
          display: 'Jenkins',
          website: 'https://www.jenkins.io/'
        },
        {
          display: 'Azure DevOps',
          website: 'https://azure.microsoft.com/en-ca/services/devops/#DevOps'
        },
        {
          display: 'Dynatrace',
          website: 'https://www.dynatrace.com/'
        },
        {
          display: 'Keptn',
          website: 'https://keptn.sh/'
        },
        {
          display: 'OWASP',
          website: 'https://www.nginx.com/'
        },
        {
          display: 'SSMS',
          website: 'https://www.keycloak.org/'
        },
        {
          display: 'Dot Cover',
          website: 'https://nx.dev/'
        },
        {
          display: 'Azure Artifacts',
          website: 'https://balsamiq.com/wireframes/'
        },
        {
          display: 'Address Complete',
          website: 'https://balsamiq.com/wireframes/'
        }
      ]
    },
    {
      name: 'Concepts',
      keywords: [
        'Agile Methodology',
        'PI Planning',
        'Microservices Architecture',
        'Monolithic Architecture',
        'Automated testing',
        'Unit testing',
        'Cross-browser debugging',
        'Functional Programming',
        'Internationalization (i18n)',
        'REST API',
        'Reactive Programming',
        'Responsive design',
        'Single Page Applications (SPAs)',
        'Continuous integration/deployment (CI/CD)'
      ]
    }
  ],
  certificates: [
    {
      name: 'SAFe Agile 5.0',
      date: 'Nov 2020',
      issuer: 'Scaled Agile',
      url: 'https://www.scaledagile.com/'
    },
    {
      name: 'AZ-900: Microsoft Azure Fundamentals',
      date: 'Nov 2023',
      issuer: 'Microsoft',
      url: 'https://learn.microsoft.com/en-us/credentials/certifications/exams/az-900/'
    },
    {
      name: 'AZ-204: Developing Solutions',
      date: 'Dec 2023',
      issuer: 'Microsoft',
      url: 'https://learn.microsoft.com/en-us/credentials/certifications/exams/az-204/'
    }
  ],
  awards: [],
  volunteer: [],
  languages: [],
  interests: [],
  references: [],
  publications: []
} as ResumeSchema;
