import React, { Component } from 'react';
import { ResumeDataContext } from '../context';
import { Experience } from './Experience';

const titleClasses = 'text-1xl font-bold uppercase text-secondary-800 mb-1 mt-2';

export class Content extends Component {
  render() {
    return (
      <>
        <div className="-mx-4 flex flex-col paper:flex-row print:flex-row">
          <div className="px-4 w-full paper:w-full print:w-full">
            <section>
              <h2 className={titleClasses}>Summary</h2>
              <hr className="border-primary-700 border-t-2 border-solid border-opacity-50" />
              <ResumeDataContext.Consumer>
                {(data) => <p>{data.basics.summary}</p>}
              </ResumeDataContext.Consumer>
            </section>
            <section>
              <h2 className={titleClasses}>Experience</h2>
              <hr className="border-primary-700 border-t-2 border-solid border-opacity-50" />
              <ResumeDataContext.Consumer>
                {(data) => <Experience data={data.work.slice(0, 1)} />}
              </ResumeDataContext.Consumer>
            </section>
          </div>
        </div>
      </>
    );
  }
}
