import React, { Component } from 'react';
import { ResumeDataContext } from '../context';
import { Profile } from './Profile';
import { ViewPDFButton } from './ViewPDFButton';

export class Header extends Component {
  render() {
    return (
      <header>
        <ResumeDataContext.Consumer>
          {(data) => (
            <>
              <div
                className="sm:text-center sm:py-4
                iphone:text-center iphone:py-4
                iphonePro:text-center iphonePro:py-4
                iphoneMax:text-center iphoneMax:py-4
                md:absolute lg:absolute xl:absolute 2xl:absolute 3xl:absolute
                md:right-16 lg:right-16 xl:right-16 2xl:right-16 3xl:right-16 
                md:top-16 lg:top-16 xl:top-16 2xl:top-16 3xl:top-16">
                {ViewPDFButton()}
              </div>
              <div className="text-center">
                <span className="text-bold text-7xl text-primary-800 leading-none mb-2 ">
                  {data.basics.name}
                </span>
              </div>
              <h4 className="text-bold text-2xl text-primary-600 leading-none mb-2 text-center">
                Sr. Full Stack Developer
              </h4>
              {Profile(data.basics)}
            </>
          )}
        </ResumeDataContext.Consumer>
      </header>
    );
  }
}
