import React from 'react';
import { ResumeSchemaCertificate } from '../../data/resume-data.interface';
import { DateFormatter } from '../../utils/date';

export const Certification = (props: { data: ResumeSchemaCertificate[] }) => {
  const certificates = props.data.map((certificate: ResumeSchemaCertificate, i: number) => {
    return (
      <div key={i} className="mb-4">
        <h3>
          <a
            className="text-0.75xl font-bold text-primary-700 hover:text-primary-600"
            href={certificate.url}
            target="_blank"
            rel="noreferrer">
            {certificate.name}
          </a>
        </h3>

        <h4 className="text-secondary-600">
          {DateFormatter(certificate?.date)}
          &nbsp;|&nbsp;
          {certificate.issuer}
        </h4>
      </div>
    );
  });

  return <div>{certificates}</div>;
};
