/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { merge } from 'lodash';
import React from 'react';
import { ResumeSchema } from '../data/resume-data.interface';

const publicResumeData = require('../data/resume-data').default;

const resumeData = merge({}, publicResumeData);

export const ResumeDataContext = React.createContext(resumeData as ResumeSchema);
